import * as React from "react";
import { ChakraProvider, Spinner } from "@chakra-ui/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { theme } from "./theme";
import Fonts from "./fonts";

import routes from "./routes";
import { HomePage } from "./pages/Home";
import { MoviePage } from "./pages/Movie";
import { TheaterPage } from "./pages/Theater";
import { RoomPage } from "./pages/Room";
import { AboutPage } from "./pages/About";
import { LegalNoticePage } from "./pages/LegalNotice";
import { NoMatchPage } from "./pages/NoMatch";
import { PageLayout } from "./components/PageLayout";
import {
  SimpleBreadcrumb,
  MovieBreadcrumb,
  RoomBreadcrumb,
  TheaterBreadcrumb,
} from "./components/Breadcrumbs";
import moment from "moment";
import "moment/locale/fr";
import { HelmetProvider } from "react-helmet-async";
moment.locale("fr");

const Loader = () => <Spinner />;

const router = createBrowserRouter([
  {
    element: <PageLayout />,
    handle: {
      getCrumb: (params: any) => <SimpleBreadcrumb content="Accueil" to="/" />,
    },
    children: [
      {
        path: routes.home.key,
        errorElement: <NoMatchPage />, // {/* 404 Page */}
        element: <HomePage />,
        loader: Loader,
      },
      {
        path: routes.movie.key,
        element: <MoviePage />,
        loader: Loader,
        handle: {
          getCrumb: (params: any) => <MovieBreadcrumb id={params.movieId} />,
        },
      },
      {
        path: routes.theater.key,
        element: <TheaterPage />,
        loader: Loader,
        handle: {
          getCrumb: (params: any) => (
            <TheaterBreadcrumb id={params.theaterId} />
          ),
        },
      },
      {
        path: routes.room.key,
        element: <RoomPage />,
        loader: Loader,
        handle: {
          getCrumb: (params: any) => <RoomBreadcrumb id={params.roomId} />,
        },
      },
      {
        path: routes.legalNotice.key,
        element: <LegalNoticePage />,
        loader: Loader,
        handle: {
          getCrumb: (params: any) => (
            <SimpleBreadcrumb content="Mentions légales" to="#" />
          ),
        },
      },
      {
        path: routes.about.key,
        element: <AboutPage />,
        loader: Loader,
        handle: {
          getCrumb: (params: any) => (
            <SimpleBreadcrumb content="L'association" to="#" />
          ),
        },
      },
    ],
  },
]);

export const App = () => (
  <HelmetProvider>
    <ChakraProvider theme={theme}>
      <Fonts />
      <RouterProvider router={router} />
    </ChakraProvider>
  </HelmetProvider>
);
