import React from "react";
import {
  Heading,
  Flex,
  StackProps,
  Alert,
  AlertIcon,
  Box,
  AlertDescription,
  CloseButton,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { DarkLightModeBtn } from "./DarkLightModeBtn";
import routes from "../routes";
import { LPLink } from "./LPLink";

// Note: This code could be better,
// so I'd recommend you to understand how I solved and you could write yours better :)
// Good luck! 🍀

// Update: Check these awesome headers from Choc UI 👇
// https://choc-ui.tech/docs/elements/headers
// 2. Use the `as` prop

const BetaAlert: React.FC = () => {
  const { isOpen, onClose: defaultOnClose } = useDisclosure({
    defaultIsOpen:
      window.localStorage.getItem("BETA_BANNER_DISMISSED") !== "true",
  });
  const onClose = () => {
    window.localStorage.setItem("BETA_BANNER_DISMISSED", "true");
    defaultOnClose();
  };

  return isOpen ? (
    <Alert status="info" backgroundColor="yellow.600" color="white" py={1}>
      <AlertIcon color="white" />
      <Box w="100%">
        <AlertDescription fontSize={"sm"}>
          L'application est encore en cours de développement. Si vous avez des
          remarques ou que vous rencontrez un bug, n'hésitez pas à nous
          contacter via{" "}
          <Link href="mailto:contact@portesducinema.fr" fontWeight={"bold"}>
            contact@portesducinema.fr
          </Link>
        </AlertDescription>
      </Box>
      <CloseButton onClick={onClose} />
    </Alert>
  ) : null;
};

export const Header: React.FC<StackProps> = (props) => {
  return (
    <>
      <BetaAlert />
      <Flex
        as={"header"}
        bgGradient="linear(to-r, #003249, #0FA3B1)"
        color="white"
        alignItems={"center"}
        {...props}
      >
        <Heading as="h1" size={{ base: "sm", sm: "md" }} fontFamily={"Inter"}>
          <LPLink to={routes.home.key} _hover={{ textDecoration: "none" }}>
            Les portes du cinéma
          </LPLink>
        </Heading>

        {/* <DarkLightModeBtn
        position={"absolute"}
        right={{ base: "25px", sm: "5vw" }}
      /> */}
      </Flex>
    </>
  );
};
