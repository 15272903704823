import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { Room } from "../pages/types";
import { APIQueryKey, loremImageUrl } from "./utils";

const room = {
  id: 1,
  name: "Salle 1",
  internal_id: 1,
  accessibility_description:
    "Salle accessible aux personnes à mobilité réduite",
  access_point: "top",
  photos: [loremImageUrl, loremImageUrl, loremImageUrl],
  theater: {
    id: 1,
    name: "Cinéma des cinéastes",
    full_name: "Cinéma des cinéastes",
    address: "7 avenue de clichy",
    zipcode: 75017,
    accessibility_description:
      "Cinéma accessible aux personnes à mobilité réduite",
    sanitory_description:
      "Toilettes accessibles aux personnes à mobilité réduite",
    popcorn_description: "Boutique accessible aux personnes à mobilité réduite",
    entry_description:
      "Entrée principale accessible aux personnes à mobilité réduite",
    photos: [],
  },
} as Room;

const getRoomKey = (id: string): APIQueryKey => ["get_room_details", id];
// const fetchTheaters = async (): Promise<Theater[]> => theaters;
const fetchRoom = (id: string): Promise<Room> => {
  return Promise.resolve(room);
  return fetch(`/api/rooms/${id}/`).then((res) => {
    return res.json();
  });
};
export const useGetRoom = (id: string): UseQueryResult<Room> => {
  return useQuery({ queryKey: getRoomKey(id), queryFn: () => fetchRoom(id) });
};
