import * as React from "react";
import {
  Flex,
  Box,
  FlexProps,
  Heading,
  Stack,
  Text,
  CardProps,
  Card,
  Image,
  CardBody,
  Center,
} from "@chakra-ui/react";
import { Theater } from "../pages/types";
import { MK2_LOGO_URL, TheaterLogo } from "./TheaterLogo";

export const BaseCard: React.FC<
  CardProps & {
    title: string;
    image: React.ReactNode;
    children?: React.ReactNode;
  }
> = ({ title, image, children, ...props }) => {
  return (
    <Card
      direction={{ base: "column", md: "row" }}
      overflow="hidden"
      variant="outline"
      {...props}
    >
      <Box>
        <Center>{image}</Center>
      </Box>

      <Stack>
        <CardBody>
          <Heading
            textAlign={{ base: "center", md: "left" }}
            size={{ base: "md", sm: "lg" }}
            marginBottom={0}
          >
            {title}
          </Heading>
          {children}
        </CardBody>
      </Stack>
    </Card>
  );
};

export const TheaterCard: React.FC<
  FlexProps & { name: string; address: string; children?: React.ReactNode }
> = ({ name, address, children, ...props }) => {
  const theaterImage = (
    <Image
      maxW={"100px"}
      w="100px"
      src={MK2_LOGO_URL}
      alt="Caffe Latte"
      m={3}
      height={"100%"}
      maxH={"100px"}
      borderRadius={"50px"}
      mb={-4}
    />
  );
  return (
    <BaseCard
      image={theaterImage}
      title={name}
      size={{ base: "md", sm: "lg" }}
      borderRadius={"50px"}
      {...props}
    >
      <Text textAlign={{ base: "center", md: "left" }} my="2" fontSize={"sm"}>
        {address}
      </Text>
      <Flex justifyContent={{ base: "center", md: "start" }}>{children}</Flex>
    </BaseCard>
  );
};
