import { Flex, Heading } from "@chakra-ui/react";

export const BlogSubPageLayout: React.FC<
  React.PropsWithChildren<{ title: string }>
> = ({ title, children }) => {
  return (
    <Flex direction="column" w="100%">
      <Heading as="h2" size="md" mt={6}>
        {title}
      </Heading>
      {children}
    </Flex>
  );
};
