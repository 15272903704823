import * as React from "react";
import { useState, useMemo, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  Box,
  Text,
  FormControl,
  Heading,
  Image,
  Card,
  CardBody,
  Tooltip,
  FlexProps,
  Spinner,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { TheaterLogo } from "../components/TheaterLogo";
import { useGetTheaters } from "../data/theater";
import {
  useGetSearchMovies,
  useListMoviesHotests,
  useListMoviessLatests,
} from "../data/movie";
import { Movie, SearchMode, Theater } from "./types";
import routes from "../routes";
import { LPLink, LPLinkOverlay } from "../components/LPLink";
import { getYear } from "../utils";
import { MovieLogo } from "../components/MovieLogo";
import { MovieCast } from "../components/MovieCast";

type ToggleSearchModeProps = {
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchMode: SearchMode;
  setSearchMode: React.Dispatch<React.SetStateAction<SearchMode>>;
};

const SearchForm = ({
  searchMode,
  setSearchMode,
  handleSearch,
}: ToggleSearchModeProps) => {
  const btnWidth = {
    base: "50%",
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Stack
        spacing={4}
        p="1rem"
        backgroundColor="brand.initialColorMode"
        color="brand.initialColorMode"
        boxShadow="md"
      >
        <Flex justifyContent={"space-between"}>
          <Button
            width={btnWidth}
            variant="with-shadow"
            color={searchMode === "MOVIE" ? "white" : "gray.700"}
            backgroundColor={searchMode === "MOVIE" ? "blue.800" : "gray.200"}
            transform={searchMode === "MOVIE" ? "scale(1)" : "scale(0.85)"}
            onClick={() => {
              setSearchMode("MOVIE");
            }}
            mr={1}
          >
            Film
          </Button>
          <Button
            ml={1}
            width={btnWidth}
            color={searchMode === "THEATER" ? "white" : "gray.700"}
            backgroundColor={searchMode === "THEATER" ? "blue.800" : "gray.200"}
            transform={searchMode === "THEATER" ? "scale(1)" : "scale(0.85)"}
            variant="with-shadow"
            onClick={() => {
              setSearchMode("THEATER");
            }}
          >
            Cinéma
          </Button>
        </Flex>
        <FormControl
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputGroup>
            <InputLeftElement pointerEvents="none" />
            <Input
              autoFocus
              pl={3}
              type="text"
              placeholder={
                searchMode === "MOVIE"
                  ? "Rechercher un film"
                  : "Rechercher un cinéma"
              }
              onChange={handleSearch}
            />
          </InputGroup>
        </FormControl>
      </Stack>
    </form>
  );
};

const HomeMoviesDisplay: React.FC<
  { movies: Movie[]; sectionName: string } & FlexProps
> = ({ movies, sectionName, ...props }) => {
  if (!movies?.length) {
    return null;
  }
  return (
    <Flex flexDirection="column" {...props}>
      <Heading as="h2" size="md" textAlign={"center"} mt="7" mb="3">
        {sectionName}
      </Heading>

      <Card
        flexDirection="row"
        overflowX={"auto"}
        overflowY={"hidden"}
        maxWidth={{ base: "90vw", lg: "80vw" }}
        justifyContent={movies.length > 1 ? "flex-start" : "center"}
      >
        {movies?.map((movie) => (
          <Flex key={"home-carousel-" + movie.id} flexFlow="wrap">
            <LPLink
              to={routes.movie.render(movie.id)}
              _hover={{
                textDecoration: "none",
                color: "blue.600",
                transform: "scale(1.1)",
              }}
            >
              <CardBody width={{ base: "140px", md: "200px" }} m={2} px={0}>
                <Flex justifyContent="center">
                  <MovieLogo
                    poster_url={movie.poster_url}
                    alt={"Affiche du film " + movie.title}
                    borderRadius="lg"
                    textAlign={"center"}
                    boxShadow={"0px 0px 5px 1px var(--chakra-colors-gray-200)"}
                    width={{ base: "140px", md: "200px" }}
                    height={{ base: "180px", md: "270px" }}
                  />
                </Flex>
                <Stack mt="1" spacing="" textAlign={"center"}>
                  <Tooltip label={movie.title} hasArrow>
                    <Heading as={"h4"} size="xs" noOfLines={{ base: 2, md: 2 }}>
                      {movie.title}
                    </Heading>
                  </Tooltip>
                  <MovieCast
                    cast={movie.cast}
                    filteredPersonType="Director"
                    noOfLines={1}
                    fontSize={"xs"}
                  />
                  <Text color="blue.600" fontSize="xs" cursor="pointer">
                    Voir les séances
                  </Text>
                </Stack>
              </CardBody>
            </LPLink>
          </Flex>
        ))}
      </Card>
    </Flex>
  );
};
export const HomePage = () => {
  const [searchMode, setSearchMode] = useState<SearchMode>("MOVIE");
  const [search, setSearch] = useState<string>("");
  //   TODO use debounce ?
  // https://stackoverflow.com/questions/68105012/what-is-the-correct-way-to-pass-parameters-to-a-react-query-usequery-method-that
  // https://stackoverflow.com/questions/67897409/search-box-with-react-query
  const { data: movies } = useGetSearchMovies(search);
  const { data: theaters } = useGetTheaters();
  const [filteredTheaters, setFilteredTheaters] = useState<Theater[]>(
    theaters || [],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (searchMode === "MOVIE") {
      // useGetSearchMovies handles it
      return;
    }
    let newFilteredTheaters = [] as Theater[];
    if (search && theaters) {
      newFilteredTheaters = theaters.filter(
        (theater) =>
          theater.full_name.toLowerCase().includes(search.toLowerCase()) ||
          theater.address.toLowerCase().includes(search.toLowerCase()),
      );
    }
    setFilteredTheaters(newFilteredTheaters);
  }, [search, searchMode, theaters]);

  const { data: hotestsMovies } = useListMoviesHotests();
  const { data: latestsMovies } = useListMoviessLatests();

  const searchBoxWidth = { base: "100%", md: "450px" };
  return (
    <>
      <Helmet>
        <title>Les portes du Cinéma</title>
        <meta
          name="LesPortesDuCinema"
          content="Trouvez une salle de cinéma accessible et adaptée aux personnes en fauteuil roulant ou à mobilité réduite"
        ></meta>
      </Helmet>
      <Flex flexDirection="column">
        <Flex
          flexDirection="row"
          justifyContent="center"
          marginTop={6}
          marginBottom={6}
        >
          <Heading as="h2" size={{ base: "sm", sm: "md" }} textAlign={"center"}>
            Trouvez une séance accessible pour vous&nbsp;!
          </Heading>
        </Flex>

        <Flex flexDirection={"column"} alignItems={"center"}>
          <Box w={searchBoxWidth}>
            <SearchForm
              searchMode={searchMode}
              setSearchMode={setSearchMode}
              handleSearch={handleSearch}
            />
          </Box>
          <Box w={searchBoxWidth} boxShadow="md">
            {search !== "" && searchMode === "MOVIE" && (
              <Stack spacing={4} p="1rem" overflowY={"auto"} maxH={"300px"}>
                {movies && movies.length ? (
                  movies.map((movie, idx) => (
                    <LinkBox
                      key={movie.id}
                      display={"flex"}
                      alignItems={"center"}
                      p={1}
                      my={"0.1rem !important"}
                      backgroundColor={idx % 2 === 0 ? "gray.200" : "gray.100"}
                    >
                      <MovieLogo
                        poster_url={movie.poster_url}
                        maxH={"50px"}
                        alt="Image du film"
                        mr={5}
                      />
                      <LPLinkOverlay to={routes.movie.render(movie.id)}>
                        <Text>{movie.title}</Text>
                        <Text fontSize={"xs"}>
                          {getYear(movie.release_date)}
                        </Text>
                      </LPLinkOverlay>
                    </LinkBox>
                  ))
                ) : (
                  <div>Aucun film trouvé.</div>
                )}
              </Stack>
            )}
            {search && searchMode === "THEATER" && (
              <Stack spacing={4} p="1rem">
                {filteredTheaters.length ? (
                  filteredTheaters.map((theater, idx) => (
                    <LinkBox
                      key={theater.id}
                      display={"flex"}
                      alignItems={"center"}
                      p={1}
                      my={"0.1rem !important"}
                      backgroundColor={idx % 2 === 0 ? "gray.200" : "gray.100"}
                    >
                      <TheaterLogo
                        height="50px"
                        width="50px"
                        alt="Image du film"
                        mr={5}
                      />
                      <LPLinkOverlay to={routes.theater.render(theater.id)}>
                        <Text>{theater.full_name}</Text>
                        <Text fontSize={"xs"}>
                          {theater.address.toLocaleLowerCase()}{" "}
                          {theater.zipcode}
                        </Text>
                      </LPLinkOverlay>
                    </LinkBox>
                  ))
                ) : (
                  <div>Aucun cinéma trouvé.</div>
                )}
              </Stack>
            )}
          </Box>
        </Flex>
        {latestsMovies && (
          <HomeMoviesDisplay
            movies={latestsMovies}
            sectionName="Les sorties de la semaine"
            mt={10}
            mb={6}
            margin={"auto"}
          />
        )}
        {hotestsMovies && (
          <HomeMoviesDisplay
            movies={hotestsMovies}
            sectionName="Les films du moment"
            mt={6}
            mb={6}
            margin={"auto"}
          />
        )}
      </Flex>
    </>
  );
};

export default HomePage;
