import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";

import { Box, Flex, FlexProps, Image } from "@chakra-ui/react";
import Slider from "react-slick";
import { getLoremImageUrl } from "../sample_image";

const settings = {
  dots: true,
  arrows: true,
  fade: true,
  //   infinite: true,
  autoplay: true,
  // speed: 500,
  //   autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export const Carousel: React.FC<FlexProps> = (props) => {
  const cards = [
    getLoremImageUrl(200, 200),
    getLoremImageUrl(500, 600),
    getLoremImageUrl(100, 300),
  ];

  return (
    <Box {...props}>
      <Slider {...settings}>
        {cards.map((url, index) => (
          <Flex
            key={index}
            justifyContent="center"
            width={"100%"}
            display={"flex !important"}
            my={7}
          >
            <Image height={"md"} src={url} />
          </Flex>
        ))}
      </Slider>
    </Box>
  );
};
