import { useGetMovieWithSessions } from "../data/movie";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {
  Text,
  Box,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  CardProps,
  Spinner,
  BoxProps,
  Button,
  Divider,
  Tooltip,
  Image,
} from "@chakra-ui/react";
import { LPLink } from "../components/LPLink";

import { Language, Movie, NextMovieSession, RoomWithSessions } from "./types";
import React from "react";
import { formatDuration } from "../utils";
import routes from "../routes";
import { BaseCard, TheaterCard } from "../components/Cards";
import Moment from "react-moment";
import { DEFAULT_POSTER_URL } from "../components/MovieLogo";
import { MovieCast } from "../components/MovieCast";

export const MovieCard: React.FC<
  CardProps & {
    movie: Movie;
    children?: React.ReactNode;
  }
> = ({ movie, children, ...props }) => {
  const movieImage = (
    <Image
      maxW={"200px"}
      w="200px"
      src={movie.poster_url ? movie.poster_url : DEFAULT_POSTER_URL}
      alt="Caffe Latte"
      margin={3}
      height={"100%"}
    />
  );
  return (
    <>
      <BaseCard image={movieImage} title={movie.title} {...props}>
        <Text mb={2} textAlign={{ base: "center", sm: "left" }} fontSize={"sm"}>
          <Moment format="YYYY-MM-DD">{movie.release_date}</Moment> /{" "}
          {formatDuration(movie.duration)} /{" "}
          {movie.genres.map((genre) => genre.name).join(", ")}
        </Text>

        <Flex marginBottom={2} display={"block"} gap={5}>
          <Flex w={{ base: "100%", lg: "fit-content" }} gap={1}>
            <Text>Réalisé par</Text>
            <MovieCast
              fontStyle={"italic"}
              cast={movie.cast}
              filteredPersonType="Director"
            />
          </Flex>
          <Tooltip
            label={
              <MovieCast cast={movie.cast} filteredPersonType="Actor" p={2} />
            }
            hasArrow
            arrowSize={15}
            p={0}
          >
            <Flex gap={1}>
              <Text>Avec</Text>
              <MovieCast
                fontStyle={"italic"}
                cast={movie.cast}
                filteredPersonType="Actor"
                width={"100%"}
              />
            </Flex>
          </Tooltip>
        </Flex>
        <Text fontSize={{ base: "sm", lg: "sm" }} py="2">
          {movie.synopsis}
        </Text>
        {children}
      </BaseCard>
    </>
  );
};

const LanguageChip: React.FC<{ language: Language }> = ({ language }) => (
  <Text
    as={"span"}
    borderRadius={"50%"}
    fontWeight={"bold"}
    position="absolute"
    top={-3}
    right={-3}
    align="center"
    padding={1.5}
    fontSize={"xs"}
    bg={language === "VO" ? "red.800" : "blue.800"}
    color={"white"}
    height={"24px"}
    width={"24px"}
    display={"flex"}
    justifyContent={"center"}
    paddingTop={"4.5px"}
  >
    {language}
  </Text>
);
export const SlotsCard: React.FC<
  {
    roomWithSessions: RoomWithSessions;
  } & BoxProps
> = ({ roomWithSessions, ...props }) => {
  let linksToRoomsComponent = null;
  //   Debug to double the rooms
  //   roomWithSessions.rooms = roomWithSessions.rooms?.flatMap((i) => [i, i]);
  if (roomWithSessions.rooms) {
    if (roomWithSessions.rooms?.length === 0) {
      linksToRoomsComponent = (
        <Button
          disabled
          bg="yellow.200"
          fontWeight={"bold"}
          size="md"
          color="gray.600"
          opacity="1 !important"
          py={2}
          borderRadius={"0px"}
          px={{ base: 3, sm: 7 }}
          _hover={{ textDecoration: "none" }}
        >
          {roomWithSessions.consolidated_name}
        </Button>
      );
    } else {
      linksToRoomsComponent = (
        <Box>
          <Text fontSize={"sm"} fontStyle={"italic"}>
            Le film est susceptible d'être diffusé dans l'une de ces
            salles&nbsp;:
          </Text>
          <Flex alignItems={"center"}>
            {roomWithSessions.rooms.map((room, index, arr) => (
              <React.Fragment key={"slots-" + index}>
                {index > 0 && <Text mx={2}>ou</Text>}
                <LPLink
                  to={routes.room.render(room.id)}
                  bg="yellow.600"
                  fontWeight={"bold"}
                  size="md"
                  color="white"
                  py={2}
                  px={{ base: 3, sm: 7 }}
                  _hover={{ textDecoration: "none" }}
                >
                  {room.name}
                </LPLink>
              </React.Fragment>
            ))}
          </Flex>
        </Box>
      );
    }
  }
  return (
    <Box {...props}>
      <Flex mt={5} flexWrap="wrap" rowGap={4} mb={2}>
        {roomWithSessions.sessions.map((slot, index) => (
          <Box
            border="1px solid black"
            mr={5}
            py={1}
            px={2}
            width={"80px"}
            textAlign={"center"}
            key={"session-" + index}
            position="relative"
          >
            {slot.session_timing.slice(0, 2)}h{slot.session_timing.slice(3, 5)}
            <LanguageChip language={slot.language} />
          </Box>
        ))}
      </Flex>
      <Flex mt={2}>{linksToRoomsComponent}</Flex>
    </Box>
  );
};

export const AllSlotsCards: React.FC<{
  roomsWithSessions: RoomWithSessions[];
}> = ({ roomsWithSessions }) => {
  return (
    <>
      {roomsWithSessions.map((room_with_sessions, index, arr) => (
        <React.Fragment key={"room-with-session-" + index}>
          <SlotsCard
            roomWithSessions={room_with_sessions}
            key={"room-session-" + index}
          />
          {arr.length > 1 && index !== arr.length - 1 && (
            <Divider borderColor={"gray.500"} my={3} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};
const MovieSessionTabPannel: React.FC<{ nextDate: NextMovieSession }> = ({
  nextDate,
}) => {
  return (
    <TabPanel px={0}>
      {nextDate.theater_room_sessions.map((theater_room_session, index) => (
        <React.Fragment key={"theater-card-" + index}>
          <TheaterCard
            name={theater_room_session.name}
            address={theater_room_session.address}
            mt={2}
            mb={4}
          >
            <Box>
              <AllSlotsCards
                roomsWithSessions={theater_room_session.room_sessions}
              />
            </Box>
          </TheaterCard>
        </React.Fragment>
      ))}
    </TabPanel>
  );
};

export const MoviePage = () => {
  const { movieId = "missing id" } = useParams<{ movieId: string }>();
  const { isLoading, data: movie } = useGetMovieWithSessions(movieId);
  if (isLoading) {
    return <Spinner />;
  }
  if (!movie) {
    return <Box>Film introuvable</Box>;
  }
  return (
    <>
      <Helmet>
        <title>{movie.title} - Salles accessibles</title>
        <meta
          name={movie.title}
          content={`Trouvez une salle accessible pour vous grâce aux portes du cinéma pour le film ${movie.title}`}
        ></meta>
      </Helmet>
      <MovieCard movie={movie} mb={5} />
      {!movie.sessions?.length ? (
        <Box>Aucune séance disponible</Box>
      ) : (
        <Flex w={"100%"}>
          <Tabs variant="enclosed" colorScheme="teal" w="100%">
            <TabList overflowX={"auto"} overflowY={"hidden"}>
              {movie.sessions.map((nextDate, index) => (
                <Tab key={"tab" + index} px={5}>
                  <Text size="sm">
                    {nextDate.day_of_week}
                    <br />
                    <Moment format="DD MMM">{nextDate.session_date}</Moment>
                  </Text>
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {movie.sessions.map((nextDate, index) => (
                <MovieSessionTabPannel key={index} nextDate={nextDate} />
              ))}
            </TabPanels>
          </Tabs>
        </Flex>
      )}
    </>
  );
};
