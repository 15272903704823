import { Day, DayOfWeek } from "./pages/types";

export const getYear = (date: string) => {
  return new Date(date).getFullYear();
};
export const getFrDate = (date: string) => {
  return new Date(date).toLocaleDateString("fr-FR");
};
export const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `${hours}H${("0" + minutes).slice(-2)}`;
};
