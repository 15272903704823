import React from "react";
import { Text, Heading, Link } from "@chakra-ui/react";
import { BlogSubPageLayout } from "../components/BlogSubPageLayout";

export const LegalNoticePage = () => {
  return (
    <BlogSubPageLayout title="Mentions légales">
      <Heading as="h3" size="sm" mt={6}>
        Association Les Portes
      </Heading>
      <Text mt={2}>
        <Link href="https://www.journal-officiel.gouv.fr/pages/associations-detail-annonce/?q.id=id:202300511574">
          L'Association Les Portes
        </Link>{" "}
        est une association loi 1901 déclarée en préfecture de Paris sous le
        numéro W751272163 qui a pour but de promouvoir l'accessibilité en
        particulier pour les personnes en situation de handicap.
        <br />
        Siège social – 11 boulevard Saint Michel 75005 Paris
      </Text>
      <Heading as="h3" size="sm" mt={6}>
        Hébergement du site
      </Heading>
      <Text mt={2}>OVH – Siège social au 2 rue Kellermann 59100 Roubaix.</Text>
      <Heading as="h3" size="sm" mt={6}>
        Propriété intellectuelle
      </Heading>
      <Text mt={2}>
        Le site Les portes du cinéma est protégé par les dispositions du Code de
        la propriété intellectuelle, notamment par celles de ses dispositions
        relatives à la propriété littéraire et artistique, aux droits d'auteur
        et à la protection des bases de données. Ces droits sont la propriété
        exclusive de l'association Les Portes. Le Code de la propriété
        intellectuelle n'autorise aux termes de l'article L.122-5, d'une part,
        que les copies ou reproductions strictement réservées à l'usage privé du
        copiste et non destinées à une utilisation collective et, d'autre part,
        que les analyses et les courtes citations dans un but d'exemple et
        d'illustration. De même, l'article L.342-3 du Code de la propriété
        intellectuelle n'autorise que l'extraction ou la réutilisation d'une
        partie non substantielle du contenu d'une base de données mise à la
        disposition du public par son titulaire. Par conséquent, toute
        représentation ou reproduction non autorisée des sites internet de
        l'association Les Portes, par quelque moyen que ce soit, est strictement
        interdite sous peine de poursuites judiciaires. Le contrevenant s'expose
        à des sanctions civiles et pénales et notamment aux peines prévues aux
        articles L.335-2 et L.343-1 du Code de la propriété intellectuelle.
      </Text>
      <Heading as="h3" size="sm" mt={6}>
        Cookies
      </Heading>
      <Text mt={2}>
        La navigation sur le site ne nécessite pas l'utilisation de cookies. Par
        ailleurs, si l'utilisateur autorise les cookies, ces derniers
        permettront d'élaborer des statistiques de fréquentation du site de
        manière anonyme.
      </Text>
    </BlogSubPageLayout>
  );
};
