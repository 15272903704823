import { Flex, Spinner, Box } from "@chakra-ui/react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Breadcrumbs } from "./Breadcrumbs";
import { Outlet, useMatches } from "react-router-dom";

const FOOTER_MOBILE_HEIGHT = 185;
const FOOTER_DESKTOP_HEIGHT = 97;
const footerHeight = {
  base: `${FOOTER_MOBILE_HEIGHT}px`,
  sm: `${FOOTER_DESKTOP_HEIGHT}px`,
};
const bodyMinHeight = {
  base: `calc(100vh - ${FOOTER_MOBILE_HEIGHT}px)`,
  sm: `calc(100vh - ${FOOTER_DESKTOP_HEIGHT}px)`,
};
const paddingX = { base: 4, sm: 6 };
const paddingY = { base: 4, sm: 6 };

export const PageLayout: React.FC<
  React.PropsWithChildren<{ isLoading?: boolean }>
> = ({ isLoading }) => {
  // https://stackoverflow.com/questions/28128400/keep-footer-at-the-bottom-of-the-page-with-scrolling-if-needed
  return (
    <>
      <Box minHeight={bodyMinHeight}>
        <Header px={paddingX} py={paddingY} />

        <Flex px={paddingX} py={paddingY} flexDirection="column">
          <Breadcrumbs mb={2} />
          {isLoading ? (
            <Flex mt={4} w="100%" justifyContent={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <Outlet />
          )}
        </Flex>
      </Box>

      <Footer py={{ base: 4, sm: 4 }} px={paddingX} height={footerHeight} />
    </>
  );
};
