import { extendTheme } from "@chakra-ui/react";

// https://themera.vercel.app
export const theme = extendTheme({
  colors: {
    blue: {
      "50": "#E5F7FF",
      "100": "#B8E9FF",
      "200": "#8ADAFF",
      "300": "#5CCCFF",
      "400": "#2EBDFF",
      "500": "#00AFFF",
      "600": "#008CCC",
      "700": "#006999",
      "800": "#004666",
      "900": "#002333",
    },
    cyan: {
      "50": "#E7FBFD",
      "100": "#BDF4F9",
      "200": "#93EDF6",
      "300": "#69E6F2",
      "400": "#3EDFEF",
      "500": "#14D8EB",
      "600": "#10ADBC",
      "700": "#0C828D",
      "800": "#08575E",
      "900": "#042B2F",
    },
    yellow: {
      "50": "#FAF7EA",
      "100": "#F2E8C5",
      "200": "#E9D99F",
      "300": "#E1CA7A",
      "400": "#D8BC55",
      "500": "#D0AD2F",
      "600": "#A68A26",
      "700": "#7D681C",
      "800": "#534513",
      "900": "#2A2309",
    },
    brand: {
      primary: "#7EE2B5",
      secondary: "#D54FAF",
      dangerous: "#F30303",
      success: "#0EE733",
      //   cinemaccsGray: "#EDF2F7",
      initialColorMode: "light",
      useSystemColorMode: false,
    },
  },

  fonts: {
    body: `'Quicksand_Bold'`,
  },

  components: {
    Heading: {
      baseStyle: {
        // letterSpacing: "0.07em",
      },
    },
  },
});
