import React from "react";
import {
  Text,
  Heading,
  Link as ChakraLink,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { BlogSubPageLayout } from "../components/BlogSubPageLayout";

export const AboutPage = () => {
  return (
    <BlogSubPageLayout title="Association Les Portes (ALP)">
      <Heading as="h3" size="sm" mt={6}>
        Projet associatif
      </Heading>
      <Text mt={2}>
        L'association Les Portes (ALP) - loi 1901 - a été créée en 2023 par
        trois amis et fans de cinéma. Elle a pour but de promouvoir
        l'accessibilité, en particulier pour les personnes en situation de
        handicap, en mettant en place des initiatives technologique pour
        améliorer la disponibilité et la qualité des informations dans ce
        domaine. Ce site web accès sur le cinéma en est le premier exemple.
      </Text>
      <Heading as="h3" size="sm" mt={6}>
        Bureau
      </Heading>
      <Text mt={2}>
        Le bureau de l'association est composé de :
        <UnorderedList>
          <ListItem>
            Yann Dubois De Mont-Marin (Président et fondateur)
          </ListItem>
          <ListItem>Justin Albinet (Trésorier et fondateur)</ListItem>
          <ListItem>Sofien Mzabi (Secrétaire et fondateur)</ListItem>
        </UnorderedList>
      </Text>
      <Heading as="h3" size="sm" mt={6}>
        Contact
      </Heading>
      <Text mt={2}>
        Pour plus d'informations, n'hésitez pas à nous contacter à l'adresse
        suivante :{" "}
        <ChakraLink
          href="mailto: contact@portesducinema.fr"
          textDecoration={"underline"}
        >
          contact@portesducinema.fr
        </ChakraLink>
      </Text>
    </BlogSubPageLayout>
  );
};
