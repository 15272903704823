import {
  QueryFunction,
  QueryFunctionContext,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { Movie, MovieWithSessions } from "../pages/types";
import { APIQueryKey, sampleSessions } from "./utils";
import { loremImageUrl } from "../sample_image";

// const fetchTheaters = async (): Promise<Theater[]> => theaters;
const fetchMovieWithSessions = (id: string): Promise<MovieWithSessions> => {
  //   return Promise.resolve(moveWithSessions);
  return fetch(`/api/movies/${id}/next_sessions/`).then((res) => {
    return res.json();
  });
};
export const useGetMovieWithSessions = (
  id: string,
): UseQueryResult<MovieWithSessions> => {
  return useQuery({
    queryKey: ["get_movie_details", id],
    queryFn: () => fetchMovieWithSessions(id),
  });
};

// const fetchSearchMovies = (search: string): Promise<Movie[]> => {
//   // TODO add query params for search
//   // https://stackoverflow.com/questions/68105012/what-is-the-correct-way-to-pass-parameters-to-a-react-query-usequery-method-that
//   const filteredMovies = movies.filter((movie) =>
//     movie.title.toLowerCase().includes(search.toLowerCase()),
//   );
//   return Promise.resolve(filteredMovies);
// };

const fetchSearchMovies = async (search: string) => {
  return fetch(`/api/movies/?search=${search}`).then((res) => {
    return res.json();
  });
};
export const useGetSearchMovies = (search: string): UseQueryResult<Movie[]> => {
  return useQuery({
    queryKey: ["get_movie_search", search],
    queryFn: () => fetchSearchMovies(search),
  });
};

//Fetch Hottest
const getMovieMovieHotestsKey = (): APIQueryKey => ["get_movies_hotests"];
const fetchMoviesHotests = (): Promise<Movie[]> => {
  return fetch(`/api/movies/hotests/`).then((res) => {
    return res.json();
  });
};
export const useListMoviesHotests = (): UseQueryResult<Movie[]> => {
  return useQuery({
    queryKey: getMovieMovieHotestsKey(),
    queryFn: () => fetchMoviesHotests(),
  });
};

//Fetch Latest
const getMovieMoviesLatestsKey = (): APIQueryKey => ["get_movies_latests"];
const fetchMoviesLatests = (): Promise<Movie[]> => {
  return fetch(`/api/movies/latests/`).then((res) => {
    return res.json();
  });
};
export const useListMoviessLatests = (): UseQueryResult<Movie[]> => {
  return useQuery({
    queryKey: getMovieMoviesLatestsKey(),
    queryFn: () => fetchMoviesLatests(),
  });
};
