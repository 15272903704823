import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { Theater, TheaterWithSessions } from "../pages/types";
import { APIQueryKey } from "./utils";

const getTheatersKey = (): APIQueryKey => ["get_theaters"];
const fetchTheaters = (): Promise<Theater[]> => {
  return fetch(`/api/theaters/`).then((res) => {
    return res.json();
  });
};
export const useGetTheaters = (): UseQueryResult<Theater[]> => {
  return useQuery({ queryKey: getTheatersKey(), queryFn: fetchTheaters });
};

const getTheaterKey = (id: string): APIQueryKey => ["get_theater_details", id];
// const fetchTheaters = async (): Promise<Theater[]> => theaters;
const fetchTheater = (id: string): Promise<TheaterWithSessions> => {
  //   return Promise.resolve(theaterWithSessions);
  return fetch(`/api/theaters/${id}/next_sessions/`).then((res) => {
    return res.json();
  });
};
export const useGetTheater = (
  id: string,
): UseQueryResult<TheaterWithSessions> => {
  return useQuery({
    queryKey: getTheaterKey(id),
    queryFn: () => fetchTheater(id),
  });
};
