import {
  Link as ChakraLink,
  LinkOverlay,
  LinkOverlayProps,
  LinkProps,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

export const LPLink: React.FC<LinkProps & { to: string }> = (props) => {
  return <ChakraLink as={RouterLink} {...props}></ChakraLink>;
};

export const LPLinkOverlay: React.FC<LinkOverlayProps & { to: string }> = (
  props,
) => {
  return <LinkOverlay as={RouterLink} {...props}></LinkOverlay>;
};
