import React from "react";
import { Image, ImageProps } from "@chakra-ui/react";

export const MK2_LOGO_URL = "/images/mk2.jpg";
export const TheaterLogo: React.FC<ImageProps> = (props) => (
  <Image
    src={MK2_LOGO_URL}
    width={{ base: "50px", md: "96px" }}
    height={{ base: "50px", md: "96px" }}
    {...props}
  />
);
