import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Quicksand_Regular';
        font-style: normal;
        src: url('/fonts/quicksand/Quicksand_Regular.otf') format('truetype');
      }
      @font-face {
        font-family: 'Quicksand_Bold';
        font-style: normal;
        src: url('/fonts/quicksand/Quicksand_Bold.otf') format('truetype');
      }
      `}
  />
);

export default Fonts;
