export type HttpVerb = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export type RequestSearchParams = Record<
  string,
  string | string[] | null | undefined | boolean | number | number[]
>;
// export interface RequestParams {
//   urlValues?: Record<string, string | number>;
//   searchParams?: RequestSearchParams;
// }
// export type APIQueryKey = [string, RequestParams?];
type APIQueryKeySearchParam = string | number | Record<string, string | number>;
export type APIQueryKey = [string, APIQueryKeySearchParam?];

export const sampleSessions = [
  {
    day: "Jeudi",
    date: "2021-10-09",
    time: "20:00",
    language: "VF",
    reservation_link: "https://codephenix.fr",
  },
  {
    day: "Vendredi",
    date: "2021-10-10",
    time: "22:00",
    language: "VO",
    reservation_link: "https://codephenix.fr",
  },
  {
    day: "Samedi",
    date: "2021-10-11",
    time: "22:00",
    language: "VO",
    reservation_link: "https://codephenix.fr",
  },
  {
    day: "Dimanche",
    date: "2021-10-12",
    time: "22:00",
    language: "VO",
    reservation_link: "https://codephenix.fr",
  },
];

export const loremIpsum = `
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita
    quisquam illo consequatur at eaque enim, amet, non reprehenderit
    nobis, atque eveniet cupiditate deserunt dolore sequi officia minima
    nihil beatae sed`;

export const loremImageUrl = "https://via.placeholder.com/150";
