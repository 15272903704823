import React, { useMemo } from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { CastPerson, CastPersonType } from "../pages/types";

export const MovieCast: React.FC<
  { cast: CastPerson[]; filteredPersonType: CastPersonType } & TextProps
> = ({ cast, filteredPersonType, ...props }) => {
  const filteredCast = useMemo(
    () => cast.filter((person) => person.personType === filteredPersonType),
    [cast, filteredPersonType],
  );
  return (
    <Text {...props}>
      {filteredCast.length === 0
        ? "Inconnu"
        : filteredCast
            .map((person) =>
              [person.firstName, person.lastName].filter(Boolean).join(" "),
            )
            .join(", ")}
    </Text>
  );
};
