"use client";
import React from "react";
import { Box, StackDivider, VStack, useMediaQuery } from "@chakra-ui/react";
import { FaDotCircle, FaGithub } from "react-icons/fa";
import {
  Text,
  Icon,
  Link as ChakraLink,
  StackProps,
  Flex,
} from "@chakra-ui/react";
import routes from "../routes";
import { theme } from "../theme";
import { LPLink } from "./LPLink";

export const Footer: React.FC<StackProps> = (props) => {
  const thisYear = new Date().getFullYear();
  const smSize = theme.breakpoints.sm;
  const [isSmallerThanSM] = useMediaQuery(`(max-width: ${smSize})`);

  return (
    <VStack
      bgGradient="linear(to-r, #003249, #0FA3B1)"
      color="white"
      as="footer"
      spacing={2}
      divider={<StackDivider />}
      {...props}
    >
      {/* Mobile */}
      {isSmallerThanSM ? (
        <Box w="100%">
          <Flex direction="column" alignItems={"center"}>
            <Flex>
              <LPLink to={routes.home.key}>Accueil</LPLink>
            </Flex>
            <Flex>
              <LPLink to={routes.about.key}>L'association</LPLink>
            </Flex>
            <Flex>
              <LPLink to={routes.legalNotice.key}>Mentions légales</LPLink>
            </Flex>
          </Flex>
        </Box>
      ) : (
        <Box w="100%">
          <Text as="p">
            <LPLink mr={2} to={routes.home.key}>
              Accueil
            </LPLink>
            |
            <LPLink mx={2} to={routes.about.key}>
              L'association
            </LPLink>
            |
            <LPLink mx={2} to={routes.legalNotice.key}>
              Mentions légales
            </LPLink>
          </Text>
        </Box>
      )}

      {isSmallerThanSM ? (
        <Flex w="100%" direction="column" alignItems={"center"}>
          <Text as="p">{thisYear}</Text>
          <Text as="p">
            <ChakraLink href="mailto: contact@portesducinema.fr">
              contact@portesducinema.fr
            </ChakraLink>
          </Text>
          <ChakraLink
            href="https://github.com/les-portes-alp/les-portes-du-cinema"
            isExternal
            pt={2}
          >
            <Icon as={FaGithub} transform={"scale(1.5)"} />
          </ChakraLink>
        </Flex>
      ) : (
        <Flex w="100%" justifyContent={"space-between"}>
          <Text as="p" verticalAlign={"middle"}>
            {thisYear}
            <Icon as={FaDotCircle} mx={2} boxSize={2} mb="1px" />
            <ChakraLink href="mailto: contact@portesducinema.fr">
              contact@portesducinema.fr
            </ChakraLink>
          </Text>
          <ChakraLink
            href="https://github.com/les-portes-alp/les-portes-du-cinema"
            isExternal
            pt={1}
            pr={1}
          >
            <Icon as={FaGithub} transform={"scale(1.5)"} />
          </ChakraLink>
        </Flex>
      )}
    </VStack>
  );
};
