import routes from "../routes";
import { LPLink } from "../components/LPLink";
import { BlogSubPageLayout } from "../components/BlogSubPageLayout";

export const NoMatchPage = () => {
  return (
    <BlogSubPageLayout title="Page introuvable">
      <LPLink to={routes.home.key}>
        {/* <Icon as={FaArrowRight} /> */}
        Revenir à l'accueil
      </LPLink>
    </BlogSubPageLayout>
  );
};
