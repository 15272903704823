import {
  Flex,
  Card,
  Text,
  Box,
  Heading,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  AspectRatio,
  UnorderedList,
  List,
  ListItem,
} from "@chakra-ui/react";

import { useGetRoom } from "../data/room";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Carousel } from "../components/Carousel";
import { TheaterCard } from "../components/Cards";
import { useParams } from "react-router-dom";

export const RoomPage = () => {
  let { roomId = "missing id" } = useParams<{ roomId: string }>();
  const { isLoading, data: room } = useGetRoom(roomId);
  if (isLoading) {
    return <Spinner />;
  }
  if (!room) {
    return <div>Erreur, salle non trouvée</div>;
  }
  if (!room.theater) {
    return <div>Erreur, salle non rattachée à un cinéma</div>;
  }
  return (
    <>
      {/* <Heading size={"lg"} marginTop={5} marginBottom={5}>
        Accessibilité de la {room.name.toLowerCase()}
      </Heading> */}

      <TheaterCard
        name={room.theater.full_name + " - " + room.name}
        address={room.theater.address}
        mb={4}
      />
      <Flex flexDirection="column" w="100%" mt={4}>
        <Tabs isFitted>
          <TabList>
            <Tab>Salle</Tab>
            <Tab>Accueil</Tab>
            <Tab>Couloirs</Tab>
            <Tab>Toilettes</Tab>
            <Tab>Sortie</Tab>
          </TabList>

          <TabPanels>
            {/* Salle */}
            <TabPanel>
              <Flex
                flexDirection={{ base: "column", md: "row" }}
                gap={{ base: "0", md: 3 }}
              >
                <Box
                  w={{ base: "100%", md: "30%" }}
                  borderRight={{ base: "none", md: "1px solid gray" }}
                >
                  <UnorderedList>
                    <ListItem>Nombre de places: 543</ListItem>
                    <ListItem>Nombre d'espaces fauteuil: 12</ListItem>
                    <ListItem>Nombre de places de plein pied: 14</ListItem>
                    <ListItem>Distance 1er rang/écran: 5m</ListItem>
                    <ListItem>Dimensions de l'écran: 43m x 24m</ListItem>
                    <ListItem>Hauteur d'assise: 60cm</ListItem>
                    <ListItem>Largeur d'assise: 80cm</ListItem>
                    <ListItem>Etage: 2ème</ListItem>
                    <ListItem>Taille de la porte: 90cm</ListItem>
                  </UnorderedList>
                </Box>
                <Flex w={{ base: "100%", md: "70%" }} flexDirection={"column"}>
                  <Flex justifyContent={"center"}>
                    <Box>
                      <iframe
                        title="naruto"
                        src="https://www.youtube.com/embed/QhBnZ6NPOY0"
                        allowFullScreen
                      />
                    </Box>
                  </Flex>
                  <Card mt={3} p={3} bg="#D8D9DA">
                    <Text mt={3}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit. Sed Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Sed
                    </Text>
                  </Card>
                  <Box>
                    <Carousel my={7} mx={3} />
                  </Box>
                </Flex>
              </Flex>
            </TabPanel>
            {/* Accueil */}
            <TabPanel>
              <Flex flexDirection={"column"}>
                <Flex justifyContent={"center"}>
                  <Box>
                    <iframe
                      title="naruto"
                      src="https://www.youtube.com/embed/QhBnZ6NPOY0"
                      allowFullScreen
                    />
                  </Box>
                </Flex>
                <Card mt={3} p={3} bg="#D8D9DA">
                  <Text mt={3}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  </Text>
                </Card>
              </Flex>
            </TabPanel>

            {/* Parcours */}
            <TabPanel>
              <Flex flexDirection={"column"}>
                <Flex justifyContent={"center"}>
                  <Box>
                    <iframe
                      title="naruto"
                      src="https://www.youtube.com/embed/QhBnZ6NPOY0"
                      allowFullScreen
                    />
                  </Box>
                </Flex>
                <Card mt={3} p={3} bg="#D8D9DA">
                  <Text mt={3}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  </Text>
                </Card>
              </Flex>
            </TabPanel>

            {/* Toilettes */}
            <TabPanel>
              <Flex flexDirection={"column"}>
                <Card mt={3} p={3} bg="#D8D9DA">
                  <Text mt={3}>Toilettes dans la salle</Text>
                  <Text mt={3}>A noter: 1 escalier, toilettes mixtes</Text>
                  <Box>
                    <iframe
                      title="naruto"
                      src="https://www.youtube.com/embed/QhBnZ6NPOY0"
                      allowFullScreen
                    />
                  </Box>
                </Card>
                <Card mt={3} p={3} bg="#D8D9DA">
                  <Text mt={3}>Toilettes devant la salle</Text>
                  <Text mt={3}>A noter: 1 escalier, toilettes mixtes</Text>
                  <Box>
                    <iframe
                      title="naruto"
                      src="https://www.youtube.com/embed/QhBnZ6NPOY0"
                      allowFullScreen
                    />
                  </Box>
                </Card>
              </Flex>
            </TabPanel>

            {/* Sortie */}
            <TabPanel>
              <Flex flexDirection={"column"}>
                <Flex justifyContent={"center"}>
                  <Box>
                    <iframe
                      title="naruto"
                      src="https://www.youtube.com/embed/QhBnZ6NPOY0"
                      allowFullScreen
                    />
                  </Box>
                </Flex>
                <Card mt={3} p={3} bg="#D8D9DA">
                  <Text mt={3}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  </Text>
                </Card>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  );
};
