// https://dev.to/ihormaslov/reverse-patterns-to-url-react-router-config-2gb8

const routes = {
  home: { key: "/", render: () => "/" },
  about: { key: "/association", render: () => "/association" },
  legalNotice: { key: "/mentions-legales", render: () => "/mentions-legales" },
  theater: {
    key: "/cinema/:theaterId",
    render: (id: string | number) => "/cinema/" + id,
  },
  movie: {
    key: "/film/:movieId",
    render: (id: string | number) => "/film/" + id,
  },
  room: {
    key: "/salle/:roomId",
    render: (id: string | number) => "/salle/" + id,
  },
};

// const routes = {
//   home: "/",
//   theater: "/cinema/:theaterId",
//   movie: "/film/:movieId",
//   room: "/salle/:roomId",
//   about: "/association",
//   legalNotice: "/mentions-legales",
// };

// type ValueOf<T> = T[keyof T];
// export type Routes = typeof routes;
// export type Route = ValueOf<Routes>;

// export const renderRoute = (
//   route: Route,
//   key: string,
//   id: number | undefined
// ): string => {
//   if (id === undefined) {
//     return "/#undefined_id";
//   }
//   return route.replace(`:${key}Id`, id.toString());
// };

export default routes;
