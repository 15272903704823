import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Tab,
  Spinner,
  Text,
  Divider,
} from "@chakra-ui/react";
import { Movie, NextTheaterSession } from "./types";
import { useGetTheater } from "../data/theater";
import { TheaterCard } from "../components/Cards";
import { AllSlotsCards, MovieCard } from "./Movie";
import { useParams } from "react-router-dom";
import Moment from "react-moment";

const TheaterSessionTabPannel: React.FC<{ nextDate: NextTheaterSession }> = ({
  nextDate,
}) => {
  return (
    <TabPanel px={0}>
      {nextDate.movie_room_sessions.map((movie_room_session, index) => (
        <MovieCard
          key={index}
          movie={movie_room_session as Movie}
          mt={2}
          mb={4}
        >
          <AllSlotsCards roomsWithSessions={movie_room_session.room_sessions} />
        </MovieCard>
      ))}
    </TabPanel>
  );
};
export const TheaterPage = () => {
  const { theaterId = "missing id" } = useParams<{ theaterId: string }>();
  const { data: theater, isLoading } = useGetTheater(theaterId);
  if (isLoading) {
    return <Spinner />;
  }
  if (!theater) {
    return <Box>Cinéma introuvable</Box>;
  }
  return (
    <>
      <TheaterCard name={theater.full_name} address={theater.address} mb={5} />
      {!theater.sessions ? (
        <Box>Aucun film disponible</Box>
      ) : (
        <Flex w={"100%"}>
          <Tabs variant="enclosed" colorScheme="teal" w="100%">
            <TabList>
              {theater.sessions.map((nextDate, index) => (
                <Tab key={"tab" + index} px={5}>
                  <Text size="sm">
                    {nextDate.day_of_week}
                    <br />
                    <Moment format="DD MMM">{nextDate.session_date}</Moment>
                  </Text>
                </Tab>
              ))}
            </TabList>
            <TabPanels px={0}>
              {theater.sessions.map((nextDate, index) => (
                <TheaterSessionTabPannel key={index} nextDate={nextDate} />
              ))}
            </TabPanels>
          </Tabs>
        </Flex>
      )}
    </>
  );
};
